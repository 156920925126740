import React from 'react';

function PrivacyPolicy() {
  return (
    <div className='privacyPolicy'>
        
    </div>
  )
}

export default PrivacyPolicy;